<template>
  <v-dialog v-model="dialog.status" @click:outside="closeDialog" @keydown.esc="closeDialog" max-width="800px">
    <v-form ref="form" @submit.prevent="submitForm" :disabled="disabled">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline" v-if="dialog.item">Редактировать</span>
          <span class="headline" v-else>Добавить</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  label="Скорость"
                  type="text"
                  autofocus
                  v-model="item.speed"
                  :error-messages="errors.speed"
                ></v-text-field>
                <v-text-field
                  label="Единица измерения"
                  type="text"
                  v-model="item.type"
                  :error-messages="errors.type"
                ></v-text-field>
                <v-text-field
                    label="Скорость в бит/с"
                    type="text"
                    v-model="item.speed_in_bit"
                    hint="Подсказка 1000бит/с = 1Кбит/с, 1000Кбит/с = 1Мбит/с, 1000Мбит/с = 1Гбит/с"
                    :error-messages="errors.speed_in_bit"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
            :disabled="disabled"
          >
            Отмена
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            type="submit"
            :disabled="disabled"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
  export default {
    data () {
      return {
        disabled: false,
        loading: false,
        errors: false,
      }
    },
    computed: {
      ...mapGetters({
        dialog: 'dialog/DIALOG',
        speeds: 'speeds/SPEEDS',
      }),

      item: function () {
        var item = this.dialog.item;
        if (item) {
          return {
            id: item.id,
            speed: item.speed,
            speed_in_bit: item.speed_in_bit,
            type: item.type
          }
        }
        else {
          return {
            speed: '',
            speed_in_bit: '',
            type: ''
          }
        }
      }
    },
    methods: {
      ...mapActions({
        unsetDialog: 'dialog/SET_DIALOG_FALSE',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        getSpeeds: 'speeds/GET_SPEEDS',
        storeSpeed: 'speeds/STORE_SPEED',
        updateSpeed: 'speeds/UPDATE_SPEED',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE'
      }),

      closeDialog () {
        this.unsetDialog();
        this.errors = false;
      },

      clearItem () {
        this.item.speed = null;
        this.item.speed_in_bit = null;
        this.item.type = null;
      },

      successActions (snackbar) {
        this.setSnackbar(snackbar);
        this.closeDialog();
        this.errors = false;
        this.disabledAndLoading();
        this.getSpeeds({ page: this.speeds.meta.current_page, per_page: this.speeds.meta.per_page })
          .then(() => {
            this.nonDisabledAndLoading();
          });
      },

      submitForm () {
        this.disabled = this.loading = true;
        if (this.dialog.item) {
          this.updateSpeed(this.item)
            .then(() => {
                this.successActions({message: "Скорость изменена"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
        else {
          this.storeSpeed(this.item)
            .then(() => {
              this.clearItem();
              this.successActions({message: "Скорость добавлена"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
      }
    }
}
</script>
